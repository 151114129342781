///
/// Vuex Module - Navigation
///

import * as types from '../types'
import { auth } from '@/lib/auth'
import Cookies from 'js-cookie'
import { getMainDomain, getSubDomain } from '@/lib/utils'

const domain = getSubDomain()
const HELP_KEY = 'cs.initialHelp'

/// STATE
const state = () => ({
	menuOpen: false,
	menus: {
		header: [
			{
				title: 'Opportunities',
				link: {
					name: 'main',
				},
				admin: false,
			},
			{
				title: 'Rates',
				link: {
					name: 'rates',
				},
				admin: false,
			},
			{
				title: 'Activity',
				link: {
					name: 'activity',
				},
				admin: false,
			},
		],
		admin: [
			{
				title: 'Organizations',
				link: {
					name: 'organizations',
				},
				admin: true,
			},
			{
				title: 'Users',
				link: {
					name: 'users',
				},
				admin: true,
			},
			{
				title: 'Tools',
				link: {
					name: 'tools',
				},
				admin: true,
			},
			{
				title: 'Settings',
				link: {
					name: 'settings',
				},
				admin: false,
			},
		],
		bval: [
			{
				title: 'BVAL',
				link: {
					name: 'bval',
				},
				admin: true,
			},
		],
	},
	helpModalOpen: !Cookies.get(HELP_KEY, { domain, }),
})

/// ACTIONS
const actions = {
	menuToggle({ state, commit }, status) {
		if (!status) {
			status = !state.menuOpen
		}
		commit(types.MENU_TOGGLE, status)
	},
	helpModalToggle({ commit, state }, status) {
		if (!status) {
			status = !state.helpModalOpen
		}
		commit(types.HELP_MODAL_TOGGLE, status)
	},
}

/// MUTATIONS
const mutations = {
	[types.MENU_TOGGLE](state, status) {
		state.menuOpen = status
	},
	[types.HELP_MODAL_TOGGLE](state, status) {
		state.helpModalOpen = status

		if (!status) {
			Cookies.set(HELP_KEY, 'true', {
				domain,
				secure: true,
				expires: (365 * 3),
			})
		}
	},
}

/// GETTERS
const getters = {
	menuOpen: state => state.menuOpen,
	menu: state => key => {
		if (key == 'admin') {
			const user = auth.getUser()
			if (!user || !user.permissions || !user.permissions.admin) return []
		}
		return state.menus[key]
	},
	helpModalOpen: state => state.helpModalOpen,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
