
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import parse from 'date-fns/parse'

export default function date(val = 0, addSuffix = true, strict = true) {
	if (!val) return ''
	if (typeof val.toDate !== 'undefined')
		val = val.toDate()
	if (strict)
		return formatDistanceToNowStrict(new Date(val), { addSuffix })
	return formatDistanceToNow(new Date(val), { addSuffix })
}

