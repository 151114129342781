/**
 * Vue Filter - Enums
 */

// Dependencies
import * as _enums from '@/lib/enums'

/**
 * Return enumerated string from key/value.
 * @param {mixed} val Value
 * @param {string} key Enum key
 */
export default function enums(val, key) {
	const opts = _enums[key]
	if (!opts) {
		return val
	}
	return opts[val]
}
