/**
 * Vue Filter - User
 */

/**
 *
 * @param {number} val Number value
 * @param {boolean} usePref Use prefix
 */
export default function user(user = {}, sep = ' ', emailSep ) {
	if (!user) return user

	let formatted = [user.firstName, user.lastName].filter(e => e != null).join( sep )
	if (emailSep) {
		formatted = [formatted, user.email].filter(e => e != null).join( emailSep )
	}
	// if (org.address) {
	// 	formatted = [org.name, [org.address.city, org.address.state].join(', ')].join(sep)
	// }

	return formatted
}
