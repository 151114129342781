/**
 * Vue Filter - Date
 */

// Dependencies
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

/**
 * Change date format.
 * @param {number} val Date value
 * @param {string} f Date format
 */
export default function date(val = 0, f = 'MM/dd/yyyy') {
	if (!val) return ''
	if (typeof val.toDate !== 'undefined') {
		try {
			if (parseISO(val) == 'Invalid Date') throw new Error(`Invalid date: ${val}`)
			val = parseISO(val)
		} catch (error) {
		}
		val = val.toDate()
	} else {
		try {
			if (parseISO(val) == 'Invalid Date') throw new Error(`Invalid date: ${val}`)
			val = parseISO(val)
		} catch (error) {
		}
	}
	return format(new Date(val), f)
}
