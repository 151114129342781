import { parseDomain, ParseResultType } from 'parse-domain'

export const getMainDomain = () => {
	let thisDomain = window.location.hostname

	try {
		const parseResult = parseDomain(window.location.hostname)
		if (parseResult.type == ParseResultType.Listed) {
			const { subDomains, domain, topLevelDomains } = parseResult
			thisDomain = [domain, topLevelDomains].join('.')
		}
	} catch (error) {
		console.warn('catch', error)
	}

	return thisDomain
}

export const getSubDomain = () => {
	let thisDomain = window.location.hostname
	try {
		const parseResult = parseDomain(window.location.hostname)
		if (parseResult.type == ParseResultType.Listed) {
			const { subDomains, domain, topLevelDomains } = parseResult

			thisDomain = [subDomains.pop(), domain, topLevelDomains].join('.')
		}
	} catch (error) {
		console.warn('catch', error)
	}

	return thisDomain
}

export function chartToPng(node, elem, id) {
	return new Promise((resolve, reject) => {
		try {
			elem.setSize(520, 280)
			const svgString = elem.getSVG()
			const ref = node.querySelector(`#${id}`)
			const canvas = document.createElement('canvas')
			const ctx = canvas.getContext('2d')

			const img = document.createElement('img')
			const { height, width } = document.getElementById(id).getBoundingClientRect()
			canvas.width = width
			canvas.height = height

			let svgData = btoa(unescape(encodeURIComponent(svgString)))

			img.setAttribute('src', `data:image/svg+xml;base64,${svgData}`)
			img.onload = () => {
				ctx.drawImage(img, 0, 0)
				const dataUrl = canvas.toDataURL('image/png', 100)
				const pngImg = document.createElement('img')
				pngImg.src = dataUrl

				ref.parentNode.replaceChild(pngImg, ref)

				resolve(id)
			}
		} catch (error) {
			reject(error)
		}
	})
}

export const toNumber = (s) => {
	if (!s) return 0
	// Check if negative wrap
	if (s.indexOf('(') == 0) {
		s = s.replace(/\(|\)/g, '')
		return 0 - Number(s.toString().replace(/\,/g, ''))
	}
	return Number(s.toString().replace(/\,/g, ''))
}

export const toNegative = (s) => {
	if (s.indexOf('-') > -1) {
		return `(${s.replace('-', '')})`
	}
	return s
}

export const toCurrency = (s, precision = 4, prefix = '$') => {
	let n = toPrecision(s, precision)
	const num = (new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(n))
	return toNegative(prefix + num)
}

export const toPrecision = (s, precision = 4) => {
	if (!s) return 0
	s = toNumber(`${s}`)
	let x = Math.pow(10, precision)

	return Math.round(s * x) / x
}

export function forceDownload(data, filename = `download.csv`, options = {}) {
	const url = window.URL.createObjectURL(new Blob([data], options))
	const link = document.createElement('a')
	link.href = url
	link.setAttribute('download', filename)
	document.body.appendChild(link)
	link.click()
	link.parentNode.removeChild(link)
}
