/**
 * Vue Filter - Percentage
 */

/**
 * Convery number to percentage string.
 * @param {number} val Number
 * @param {boolean} usePref Use prefix.
 */
export default function basisPoints(val = 0, suffix = ' b.p.', digits = 2) {
	if (!val) return
	return Math.round(Number(val) * 100) + suffix
	// const formatter = new Intl.NumberFormat('en-US', {
	// 	style: 'percent',
	// 	minimumFractionDigits: digits,
	// })

	// let formatted = formatter.format(val || 0)
	// if (usePref) {
	// 	formatted = (val < 0 ? '' : '+') + formatted
	// }
	// return formatted
}
