
const TOKEN_KEY = 'cs.arc'
import Cookies from 'js-cookie'
import { getMainDomain, getSubDomain } from '@/lib/utils'

export class Auth {
	static instance
	static user

	constructor() {
	}

	static getInstance() {
		if (!Auth.instance) {
			Auth.instance = new Auth()
		}

		return Auth.instance
	}

	login(credentials) {
		const { expires_in } = credentials

		const domain = getSubDomain()
		const _toStore = JSON.stringify(credentials)
		Cookies.set(TOKEN_KEY, _toStore, {
			domain,
			secure: true,
			expires: (expires_in / 3600 / 24),
		})
	}

	logout() {
		const domain = getSubDomain()

		return Cookies.remove(TOKEN_KEY, {
			domain,
		})
	}

	getUser() {
		const domain = getSubDomain()
		let user = Cookies.get(TOKEN_KEY, {
			domain
		})
		if (!user) return
		try {
			user = JSON.parse(user)
		} catch (error) {
			console.error(error)
		}
		return user
	}

	getToken() {
		const user = this.getUser()
		if (user) {
			return user.token
		}
		return undefined
	}
}

export const auth = Auth.getInstance()
