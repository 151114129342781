
export const UserStatus = {
	active: 'Active',
	inactive: 'Inactive',
	pending: 'Pending',
	removed: 'Removed',
}

export const RegistrationStatus = {
	accepted: 'Accepted',
	pending: 'Pending',
	removed: 'Removed',
}

export const OrganizationStatus = {
	active: 'Active',
	inactive: 'Inactive',
	pending: 'Pending',
	removed: 'Removed',
}

export const ApplicationStatus = {
	active: 'Active',
	beta: 'Beta',
	inactive: 'Inactive',
	removed: 'Removed'
}

export const LoanType = {
	Taxable: 'Taxable',
	// TaxFree: 'Tax Free',
	// TaxFreeBank: 'Tax Free Bank Qualified',
}

export const LoanIndex = {
	SofrDaily: 'SOFR Paid Monthly',
	// Libor1: '1mo LIBOR',
	// Libor3: '3mo LIBOR',
}

export const AmortizationType = {
	Mortgage: 'Mortgage',
	// Level: 'Level',
}

export const DateStart = {
	0: `Spot Start`,
	1: `1mo Forward`,
	3: `3mo Forward`,
	6: `6mo Forward`,
	9: `9mo Forward`,
	12: `12mo Forward`,
	18: `18mo Forward`,
	24: `24mo Forward`,
	36: `36mo Forward`,
}

export const LegacyType = {
	fmv: `FMV`,
	ssb: `SSB`,
	atl: `ACB`,
}
