import { API } from '@/lib/api'
import * as types from '../types'
import { auth } from '@/lib/auth'
import { unset, update } from 'lodash'

//
// State
//
const state = () => ({
	rate: {}
})

const actions = {
	async get({ commit }, rateType) {
		let rate
		try {
			rate = await API.get(`/rates/${rateType}`)
			commit(types.SET_RATE, rate)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}

		return rate
	},
	async create({ commit }, { rateType, rateData }) {
		this.dispatch('misc/setLoading', true)
		let rate
		try {
			rate = await API.send(`/rates/${rateType}`, rateData)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_RATE, rate)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return rate
	},
	async update({ commit }, { rateType, rateData }) {
		this.dispatch('misc/setLoading', true)
		let rate
		try {
			rate = await API.update(`/rates/${rateType}`, rateData)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_RATE, rate)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return rate
	},
	async daily({ commit }, params) {
		this.dispatch('misc/setLoading', true)
		let rate
		try {
			rate = await API.get(`/rates/daily`, params)
			this.dispatch('misc/setLoading', false)
			// commit(types.SET_RATE, rate)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return rate
	},
	async sendDailyEmail() {
		this.dispatch('misc/setLoading', true)

		let results
		try {
			results = await API.get(`/rates/dailyEmail`)
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return results
	},
	async unset({ commit }) {
		commit(types.UNSET_RATE)
	},
}

const mutations = {
	[types.SET_RATE](state, rate) {
		state.rate = rate
	},
	[types.UNSET_RATE](state) {
		state.rate = {}
	},
}

const getters = {
	rate: state => state.rate,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
