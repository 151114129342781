
import { API } from '@/lib/api'
import * as types from '../types'
import { auth } from '@/lib/auth'

//
// State
//
const state = () => ({
	me: auth.getUser(),
	myData: {},
	user: {},
	users: [],
	authLoading: false,
	authError: null,
})

//
// Actions
//
const actions = {
	async login({ commit }, credentials) {
		let login
		try {
			login = await API.send(`/users/login`, credentials, false)
			if (login.token) {
				commit(types.LOGIN_USER, login)
			}
		} catch (error) {
			this.dispatch('misc/setError', error)
			throw error
		}

		return login
	},
	async verify({ commit }, token) {
		let login
		commit(types.SET_AUTH_LOADING, true)
		commit(types.SET_AUTH_ERROR, null)
		try {
			login = await API.send(`/users/verify`, { token }, false)
			if (login.uid) {
				login.token = token
				commit(types.LOGIN_USER, login)
			} else {
				throw new Error(`Can not authenticate. Please try again later.`)
			}
		} catch (error) {
			this.dispatch('misc/setError', error)
			commit(types.SET_AUTH_ERROR, error)
			commit(types.SET_AUTH_LOADING, false)
			throw error
		}
		commit(types.SET_AUTH_LOADING, false)
		return login
	},
	async logout({ commit }) {
		commit(types.LOGOUT_USER)
	},
	async list({ commit }, params) {
		this.dispatch('misc/setLoading', true)
		this.dispatch('misc/setError', {})

		let users
		try {
			users = await API.get(`/users`, params)
			commit(types.SET_USERS, users)
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return users
	},
	async get({ commit }, uid) {
		this.dispatch('misc/setLoading', true)
		this.dispatch('misc/setError', {})

		try {
			const user = await API.get(`/users/${uid}`)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_USER, user)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw error
		}
	},
	async update({ commit }, { uid, data }) {
		this.dispatch('misc/setError', {})
		this.dispatch('misc/setLoading', true)

		try {
			const user = await API.update(`/users/${uid}`, data)
			this.dispatch('misc/setLoading', false)
			return commit(types.SET_USER, user)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw error
		}
	},
	async unset({ commit }) {
		commit(types.UNSET_USER)
	},
	async authError({ commit }, error) {
		commit(types.SET_AUTH_LOADING, false)
		commit(types.SET_AUTH_ERROR, error)
	},
}

//
// Mutations
//
const mutations = {
	[types.LOGIN_USER](state, user) {
		state.me = user
		auth.login(user)
	},
	[types.LOGOUT_USER](state) {
		auth.logout()
		state.me = {}
		state.myData = {}
	},
	[types.SET_USERS](state, users) {
		state.users = users
	},
	[types.SET_USER] (state, user) {
		state.user = user
	},
	[types.UNSET_USER](state) {
		state.user = {}
	},
	[types.SET_AUTH_LOADING](state, status) {
		state.authLoading = status
	},
	[types.SET_AUTH_ERROR](state, error) {
		state.authError = error ? (error.message || error) : null
	},
}

//
// Getters
//
const getters = {
	me: state => state.me,
	users: state => state.users,
	authLoading: state => state.authLoading,
	authError: state => state.authError,
	isAdmin: state => state.me && state.me.permissions && state.me.permissions.admin,
	hasBval: state => state.me && state.me.permissions && state.me.permissions.bval,
	hasBvalSsb: state => state.me && state.me.permissions && state.me.permissions.bval_ssb,
	hasRecon: state => state.me && state.me.permissions && state.me.permissions.recon,
	user: state => state.user,
}

//
// Export
//
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
