///
/// Store Action Types
///

/// MENU
export const MENU_TOGGLE = 'MENU_TOGGLE'
export const HELP_MODAL_TOGGLE = 'HELP_MODAL_TOGGLE'

/// MISC
export const SET_LOADING = 'SET_LOADING'
export const SET_ERROR = 'SET_ERROR'

/// AUTHENTICATION
export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const SET_ME = 'SET_ME'
export const UNSET_ME = 'UNSET_ME'
export const SET_USER = 'SET_USER'
export const UNSET_USER = 'UNSET_USER'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING'
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR'

/// USERS
export const SET_USERS = 'SET_USERS'
export const UNSET_USERS = 'UNSET_USERS'

/// OPPORTUNITIES
export const SET_OPPORTUNITY = 'SET_OPPORTUNITY'
export const SET_OPPORTUNITIES = 'SET_OPPORTUNITIES'
export const UNSET_OPPORTUNITY = 'UNSET_OPPORTUNITY'
export const UNSET_OPPORTUNITIES = 'UNSET_OPPORTUNITIES'
export const REMOVE_OPPORTUNITY = 'REMOVE_OPPORTUNITY'
export const UPSERT_OPPORTUNITY = 'UPSERT_OPPORTUNITY'

/// ORGANIZATIONS
export const SET_ORGANIZATION = 'SET_ORGANIZATION'
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS'
export const UNSET_ORGANIZATION = 'UNSET_ORGANIZATION'
export const UNSET_ORGANIZATIONS = 'UNSET_ORGANIZATIONS'

/// PRESENTATIONS
export const SET_PRESENTATION = 'SET_PRESENTATION'
export const SET_PRESENTATIONS = 'SET_PRESENTATIONS'
export const UNSET_PRESENTATION = 'UNSET_PRESENTATION'
export const UNSET_PRESENTATIONS = 'UNSET_PRESENTATIONS'
export const REMOVE_PRESENTATION = 'REMOVE_PRESENTATION'

/// ACTIVITY
export const SET_EVENTS = 'SET_EVENTS'
export const UNSET_EVENTS = 'UNSET_EVENTS'

/// RATES
export const SET_RATE = 'SET_RATE'
export const UNSET_RATE = 'UNSET_RATE'

/// TOOLS
export const SET_BVAL = 'SET_BVAL'
export const UNSET_BVAL = 'UNSET_BVAL'
export const SET_TRADE = 'SET_TRADE'
export const UNSET_TRADE = 'UNSET_TRADE'
export const UPDATE_TRADE = 'UPDATE_TRADE'
export const SET_SYNC_RESULTS = 'SET_SYNC_RESULTS'
export const UNSET_SYNC_RESULTS = 'UNSET_SYNC_RESULTS'
export const SET_BVAL_DATES = 'SET_BVAL_DATES'
export const SET_BVAL_VALUATION = 'SET_BVAL_VALUATION'
