/* tslint:disable:no-console */

import { register } from 'register-service-worker';

let reg
if (process.env.NODE_ENV === 'production') {
	register(`${process.env.BASE_URL}service-worker.js`, {
		ready(registration) {
			reg = registration
			console.log(
				'App is being served from cache by a service worker.\n' +
				'For more details, visit https://goo.gl/AFskqB',
			);
		},
		registered(registration) {
			reg = registration
			console.log('Service worker has been registered.');
		},
		cached() {
			console.log('Content has been cached for offline use.');
		},
		updatefound(registration) {
			reg = registration
			console.log('New content is downloading.');
		},
		updated(registration) {
			reg = registration
			console.log('New content is available; please refresh.');
			const updateEvent = new CustomEvent('SWUpdated', { detail: registration.waiting });
			document.dispatchEvent(updateEvent)
		},
		offline() {
			console.log('No internet connection found. App is running in offline mode.');
		},
		error(error) {
			console.error('Error during service worker registration:', error);
		},
	});
}

let refreshing
if (typeof navigator.serviceWorker != 'undefined') {
	navigator.serviceWorker.addEventListener('controllerchange', () => {
		if (refreshing) return
		window.location.reload()
		refreshing = true
	})

	window.onfocus = () => {
		if (typeof reg === 'undefined') return
		reg.update()
	}
}
