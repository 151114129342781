
import Vue from 'vue'
import { API } from '@/lib/api'
import * as types from '../types'

const state = () => ({
	organization: {},
	organizations: [],
})

const actions = {
	async list({ commit }, params) {
		this.dispatch('misc/setLoading', true)

		let organizations
		try {
			organizations = await API.get(`/organizations`, params)
			commit(types.SET_ORGANIZATIONS, organizations)
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return organizations
	},
	async get({ commit }, orgId) {
		let organization
		try {
			organization = await API.get(`/organizations/${orgId}`)
			commit(types.SET_ORGANIZATION, organization)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}

		return organization
	},
	async update({ commit }, { orgId, data }) {
		this.dispatch('misc/setLoading', true)

		try {
			const org = await API.update(`/organizations/${orgId}`, data)
			this.dispatch('misc/setLoading', false)
			return commit(types.SET_ORGANIZATION, org)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}
	},
	async uploadLogo({ commit }, { orgId, data }) {
		this.dispatch('misc/setLoading', true)

		try {
			const org = await API.send(`/organizations/logo/${orgId}`, data, true, {
				'content-type': 'application/x-www-form-urlencoded'
			})
			this.dispatch('misc/setLoading', false)
			return commit(types.SET_ORGANIZATION, org)
		} catch (error) {
			console.error(error)
			this.dispatch('misc/setLoading', false)
		}
	},
	async salesforce({ commit }, orgId) {
		this.dispatch('misc/setLoading', true)
		let org
		try {
			org = await API.get(`/organizations/${orgId}/salesforce`)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_ORGANIZATION, org)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw error
		}

		return org
	},
	async unset({ commit }) {
		commit(types.UNSET_ORGANIZATION)
	},
}

const mutations = {
	[types.SET_ORGANIZATIONS](state, organizations) {
		state.organizations = organizations.map(o => {
			o.nameAddress = [o.name]
			o.address && o.address.city ? o.nameAddress.push(o.address.city) : null
			o.address && o.address.state ? o.nameAddress.push(o.address.state) : null
			return o
		})
	},
	[types.SET_ORGANIZATION](state, organization) {
		state.organization = organization
	},
	[types.UNSET_ORGANIZATION](state) {
		state.organization = {}
	},
}

const getters = {
	organizations: state => state.organizations,
	organization: state => state.organization,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
