/**
 * Vue Filter - Organization
 */

/**
 *
 * @param {number} val Number value
 * @param {boolean} usePref Use prefix
 */
export default function organization(org = {}, sep = ' | ') {
	if (!org) return org

	let formatted = org.name
	if (org.address) {
		formatted = [org.name, [org.address.city, org.address.state].filter(e => e != null).join(', ')].filter(e => e != null).join(sep)
	}

	return formatted
}
