///
/// API Interface
///

import Axios from 'axios'
import { auth } from '@/lib/auth'

const API_URL = process.env.API_URL || process.env.VUE_APP_API_URL
const API_URL_RAW = process.env.API_URL_RAW || process.env.VUE_APP_API_URL_RAW

Axios.defaults.baseURL = API_URL
Axios.defaults.headers.common['Content-Type'] = 'application/json'

export const API = {

	async get(path, params, options = {}, withAuth = true, headers = {}, cancel = false ) {

		const token = auth.getToken()
		if (withAuth) {
			if (!token) {
				throw new Error(JSON.stringify( { code: 400, message: `Request must be authenticated` } ))
			}
			headers.Authorization = `Bearer ${token}`
		}

		if (cancel) {
			const source = Axios.CancelToken.source()
			const cancelToken = source.token
			options.cancelToken = cancelToken
			this.requestSource = source
		}

		return this._request({
			method: 'get',
			url: path,
			params,
			headers,
			...options,
		})
	},

	async send(path, data, withAuth = true, headers = {}, raw = false) {

		const token = auth.getToken()

		if (withAuth) {
			if (!token) {
				throw new Error(JSON.stringify({ code: 400, message: `Request must be authenticated` }))
			}
			headers.Authorization = `Bearer ${token}`
		}

		return this._request({
			baseURL: raw ? API_URL_RAW : API_URL,
			method: 'post',
			url: path,
			data,
			headers,
		})
	},

	async remove(path, withAuth = true, headers = {}) {
		const token = auth.getToken()

		if (withAuth) {
			if (!token) {
				throw new Error(JSON.stringify({ code: 400, message: `Request must be authenticated` }))
			}
			headers.Authorization = `Bearer ${token}`
		}

		return this._request({
			method: 'delete',
			url: path,
			headers,
		})
	},

	async update(path, data, withAuth = true, headers = {}) {
		const token = auth.getToken()
		if (withAuth) {
			if (!token) {
				throw new Error(JSON.stringify({code: 400, message: `Request must be authenticated`}))
			}
			headers.Authorization = `Bearer ${token}`
		}

		return this._request({
			method: 'put',
			url: path,
			data,
			headers,
		})
	},

	_request(params) {
		const axios = Axios.create({
			// adapter: cacheAdapterEnhancer(Axios.defaults.adapter),
		})
		// axios.defaults.timeout = 360000
		return new Promise((resolve, reject) => {
			axios({ ...params, })
				.then(r => r.data)
				.then(resolve)
				.catch(err => {
					if (Axios.isCancel(err)) {
						this.requestSource = undefined
						return resolve()
					}

					if (err && err.response && err.response.data) {
						return reject(err.response.data)
					}
					return reject(err)
				})
		})
	},
}
