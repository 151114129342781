/**
 * Vue Filter - Percentage
 */

/**
 * Convery number to percentage string.
 * @param {number} val Number
 * @param {boolean} usePref Use prefix.
 */
export default function percentage(val = 0, usePref = false, digits = 2) {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'percent',
		minimumFractionDigits: digits,
	})

	let formatted = formatter.format(val || 0)
	if (usePref) {
		formatted = (val < 0 ? '' : '+') + formatted
	}
	return formatted
}
