/**
 * Vue Filter - Currency
 */

/**
 * Convert number to currency format.
 *
 * @param {number} val Number value
 * @param {boolean} usePref Use prefix
 */
export default function currency(val = 0, usePref = false, digits = 2, nil = false) {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: digits,
		maximumFractionDigits: digits,
	})

	if (val == 0 && nil) {
		return '-'
	}

	let formatted = formatter.format(val || 0)
	if (usePref) {
		formatted = (val < 0 ? '' : '+') + formatted
	}
	return formatted
}
