/**
 * Vue Filter - Currency
 */

/**
 * Convert number to currency format.
 *
 * @param {number} val Number value
 * @param {boolean} usePref Use prefix
 */
export default function number(val = 0, usePref = false, digits = 2) {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'decimal',
		minimumFractionDigits: digits,
	})

	let formatted = formatter.format(val || 0)
	if (usePref) {
		formatted = (val < 0 ? '' : '+') + formatted
	}
	return formatted
}
