import Confirm from './Confirm.vue'

const ConfirmPlugin = {
	install(Vue) {
		const CONSTRUCTOR = Vue.extend(Confirm)
		const CACHE = {}

		function confirm(msg, acceptAction = () => ({}), options = {}) {
			options.id = options.id || (new Date()).getTime()
			const confirm = CACHE[options.id] ||  (CACHE[options.id] = new CONSTRUCTOR())
			options.message = msg
			options.acceptAction = acceptAction

			if (!confirm.$el) {
				const vm = confirm.$mount()
				document.querySelector(options.parent  || 'body').appendChild(vm.$el)
			}
			confirm.show(options)
		}

		function confirmClose(options = {}) {
			const confirm = CACHE[options.id] || (CACHE[options.id] = new CONSTRUCTOR())
			confirm.close()
		}

		Vue.confirm = Vue.prototype.$confirm = confirm
		Vue.confirmClose = Vue.prototype.$confirmClose = confirmClose
	},
}

export default ConfirmPlugin
