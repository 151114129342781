import { API } from '@/lib/api'
import * as types from '../types'
import { auth } from '@/lib/auth'

const _DEFAULTS = {
	salesforceSyncResults: {
		updated: [],
		errors: [],
	}
}

const state = () => ({
	bvalData: {
		meta: {},
		trades: [],
		totals: {},
		totalsB: {},
		dates: [],
		salesforceSyncResults: _DEFAULTS.salesforceSyncResults,
	},
	valuationDate: '',
})

const actions = {
	async sendMarkToMarket({ commit }, data) {
		let results
		try {
			results = await API.send(`/tools/marktomarket`, data)
		} catch (error) {
			this.dispatch(`misc/setError`, error.message || error)
			throw new Error(error.message || error)
		}

		return results
	},
	async syncArcRates({ commit }) {

		let results
		this.dispatch('misc/setLoading', true)
		try {
			results = await API.send(`/tools/salesforcesync`)
			commit(types.SET_SYNC_RESULTS, results)
		} catch (error) {
			this.dispatch(`misc/setError`, error.message || error)
			throw new Error(error.message || error)
		} finally {
			this.dispatch('misc/setLoading', false)
		}

		return results

		class FatalError extends Error {}

		const token = auth.getToken()
		commit(types.UNSET_SYNC_RESULTS)
		// this.dispatch('misc/setError', {})
		// this.dispatch('misc/setLoading', true)

		// await fetchEventSource(`${API_URL}/tools/salesforcesync`, {
		// 	method: 'POST',
		// 	headers: {
		// 		'Authorization': `Bearer ${token}`,
		// 		'Content-Type': 'application/json',
		// 	},
		// 	openWhenHidden: true,
		// 	onopen: async (res) => {
		// 		console.log('evt.onOpen', { res })
		// 		if (res.ok) {
		// 			return
		// 		} else {
		// 			throw new FatalError()
		// 		}
		// 	},
		// 	onmessage: (msg) => {
		// 		console.log('evt.onMessage', { msg }, msg.event)
		// 		if (msg.event == 'FatalError') {
		// 			throw new FatalError(msg.data)
		// 		}

		// 		try {
		// 			const res = JSON.parse(msg.data)
		// 			if (res && (res.updated || res.errors)) {
		// 				commit(types.SET_SYNC_RESULTS, res)
		// 				this.dispatch('misc/setLoading', false)
		// 			}
		// 		} catch (error) {

		// 		}
		// 	},
		// 	onclose: () => {
		// 		console.log('evt.onClose')
		// 		this.dispatch('misc/setLoading', false)
		// 	},
		// 	onerror: (err) => {
		// 		console.log('evt.onError', { err }, err instanceof FatalError)
		// 		this.dispatch('misc/setLoading', false)
		// 		this.dispatch('misc/setError', err)
		// 		if (err instanceof FatalError) {
		// 			throw err
		// 		}
		// 	}
		// })
	},
	async importBvalData({ commit }) {
		let results
		try {
			results = await API.send(`/tools/bval/import`)
		} catch (error) {
			this.dispatch(`misc/setError`, error.message || error)
			throw new Error(error.message || error)
		}

		return results
	},
	cancelRequest() {
		if (API.requestSource) {
			API.requestSource.cancel('Cancelling request...')
		}
	},
	async queryBvalData({ commit, }, params) {
		let results
		try {
			results = await API.get(`/tools/bval/query`, params, undefined, true, undefined, true)
			if (results) {
				commit(types.SET_BVAL, results)
			}
		} catch (error) {
			this.dispatch(`misc/setError`, error.message || error)
			throw new Error(error.message || error)
		}

		return results
	},
	async downloadBvalData({ commit, dispatch, }, params) {
		let results
		try {
			results = await API.get(`/tools/bval/query`, params)
		} catch (error) {
			dispatch(`misc/setError`, error.message || error)
			throw new Error(error.message || error)
		}

		return results
	},
	async queryBvalDates({ commit }) {
		let results
		try {
			results = await API.get(`/tools/bval/dates`)
			commit(types.SET_BVAL_DATES, results)
		} catch (error) {
			this.dispatch(`misc/setError`, error.message || error)
			throw new Error(error.message || error)
		}

		return results
	},
	async updateBvalTrade({ commit }, data) {
		let results
		try {
			results = await API.update(`/tools/bval/trade`, data)
			commit(types.UPDATE_TRADE, results)
		} catch (error) {
			this.dispatch(`misc/setError`, error.message || error)
			throw new Error(error.message || error)
		}

		return results
	},
	async generateBvalReport({ commit }, { type, params, }) {
		let results
		try {
			results = await API.get(`/tools/bval/report/${type}`, params)
			const { meta } = results
			if (meta.valuationDate) {
				commit(types.SET_BVAL_VALUATION, meta.valuationDate)
			}
		} catch (error) {
			throw new Error(error.message || error)
		}

		return results
	},
	async updateBvalBdCollateral({ commit }, data) {
		const { counterParties } = data
		let results
		try {
			results = await API.update(`/tools/bval/bd`, { counterParties })
		} catch (error) {
			throw new Error(error.message || error)
		}

		return results
	},
	async setBvalValuation({ commit }, date) {
		commit(types.SET_BVAL_VALUATION, date)
	},
}

const mutations = {
	[types.SET_BVAL](state, data) {
		state.bvalData.trades = data.trades.map(row => {
			for(const key in row) {
				let val = row[key]
				if (val && val.value) {
					val = val.value
				}
				row[key] = val
			}
			return row
		})
		state.bvalData.totals = data.totals
		state.bvalData.totalsB = data.totalsB
		state.bvalData.meta = data.meta
		state.valuationDate = data.meta.partition.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
	},
	[types.UPDATE_TRADE](state, data) {
		const { counterparty, bdTradeId } = data
		const tradeIdx = state.bvalData.trades.find(b => b.swCounterParty == counterparty)
		if (tradeIdx > -1) {
			const trade = state.bvalData.trades[tradeIdx]
			trade = {
				...trade,
				...data,
			}
			state.bvalData.trades[tradeIdx] = trade
		}
	},
	[types.SET_BVAL_DATES](state, data) {
		state.bvalData.dates = data
	},
	[types.SET_BVAL_VALUATION](state, date) {
		state.valuationDate = date
	},
}

const getters = {
	bvalData: state => state.bvalData,
	valuationDate: state => state.valuationDate,
	trades: state => state.bvalData.trades,
	trade: state => counterparty => state.bvalData.trades.find(b => b.swCounterParty == counterparty),
	totals: state => state.bvalData.totals,
	totalsB: state => state.bvalData.totalsB,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
